import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import WORDS from './Words';
import { Easing } from '../general/Animation';
import think from '../../assets/pictures/think.gif';

export interface KeyboardLetterProps {
    letter: string;
    word: string;
    guesses: string[];
    currentGuess: string;
    setGuesses: React.Dispatch<React.SetStateAction<string[]>>;
    setCurrentGuess: React.Dispatch<React.SetStateAction<string>>;
}

const KeyboardLetter: React.FC<KeyboardLetterProps> = ({
    letter,
    guesses,
    word,
    currentGuess,
    setGuesses,
    setCurrentGuess,
}) => {
    const [bgColor, setBgColor] = useState('');
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const wordArray = word.split('');
        const wordUsed = Array(wordArray.length).fill(false);

        // Eğer harf, şu anki tahminde girildiyse, disable et
        if (currentGuess.includes(letter)) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        // Harfler girildikten sonra kontrol yapıyoruz
        const handleGuessColors = () => {
            let tempBgColor = ''; // İlgili harf için renk
            guesses.forEach((guess) => {
                const guessArray = guess.split('');

                // Adım 1: Doğru pozisyon (Yeşil)
                guessArray.forEach((guessLetter, index) => {
                    if (wordArray[index] === guessLetter) {
                        wordUsed[index] = true;
                        if (letter === guessLetter) {
                            tempBgColor = 'lightgreen'; // Yeşil
                        }
                    }
                });

                // Adım 2: Yanlış pozisyon (Sarı)
                guessArray.forEach((guessLetter, index) => {
                    if (
                        wordArray.includes(guessLetter) &&
                        letter === guessLetter &&
                        !wordUsed[wordArray.indexOf(guessLetter)]
                    ) {
                        tempBgColor = tempBgColor !== 'lightgreen' ? 'yellow' : tempBgColor; // Sarı
                    }
                });

                // Adım 3: Kelimede olmayan harf (Kırmızı)
                guessArray.forEach((guessLetter) => {
                    if (!wordArray.includes(guessLetter) && letter === guessLetter) {
                        tempBgColor = tempBgColor === '' ? 'red' : tempBgColor; // Kırmızı
                    }
                });
            });

            setBgColor(tempBgColor);
        };

        handleGuessColors(); // Renkleri hesapla
    }, [guesses, letter, word, currentGuess]); // guesses, letter, currentGuess ve word değiştiğinde çalışacak

    const handleClick = () => {
        // Eğer harf devre dışı ise, işlem yapılmaz
        if (disabled) return;

        if (letter === 'RET') {
            if (currentGuess.length === word.length) {
                setGuesses([...guesses, currentGuess]);
                setCurrentGuess('');
            }
        } else if (letter === 'DEL') {
            setCurrentGuess(currentGuess.slice(0, -1)); // Son harfi sil
        } else if (currentGuess.length < word.length) {
            setCurrentGuess(currentGuess + letter.toUpperCase()); // Harfi ekle
        }
    };

    return (
        <div
            onMouseDown={handleClick}
            className="site-button"
            style={{
                ...styles.letterBox,
                backgroundColor: bgColor || 'white',
                opacity: disabled ? 0.5 : 1, // Harf devre dışıysa şeffaflık
                cursor: disabled ? 'not-allowed' : 'pointer', // Harf devre dışıysa tıklanamaz
            }}
        >
            <p>{letter}</p>
        </div>
    );
};


export interface GuessLetterProps {
    letter: string;
    word: string;
    guess: string;
    guessed: boolean;
}

const GuessLetter: React.FC<GuessLetterProps> = ({
    guessed,
    letter,
    guess,
    word,
}) => {
    const [bgColor, setBgColor] = useState('white'); // Varsayılan renk beyaz

    useEffect(() => {
        // Eğer tahmin boşa eşitse ya da harf boşsa işlem yapma
        if (!guessed || !guess || guess.trim() === '' || letter === ' ') {
            return; // Eğer tahmin yoksa, boş harf ise işlem yapma
        }

        const wordArray = word.split(''); // Hedef kelimenin harfleri
        const guessArray = guess.split(''); // Tahmin edilen kelimenin harfleri

        // Doğru pozisyon kontrolü (Yeşil)
        const index = guessArray.indexOf(letter);
        if (index !== -1 && wordArray[index] === letter) {
            setBgColor('lightgreen'); // Yeşil: Doğru pozisyon
        }
        // Yanlış pozisyon kontrolü (Sarı)
        else if (wordArray.includes(letter) && wordArray[index] !== letter) {
            setBgColor('yellow'); // Sarı: Yanlış pozisyon
        }
        // Kelimede olmayan harf (Kırmızı)
        else if (!wordArray.includes(letter)) {
            setBgColor('red'); // Kırmızı: Kelimede yok
        }
    }, [guessed, letter, guess, word]); // `guess` veya `word` değiştiğinde çalışacak

    return (
        <div
            className="button-border"
            style={{
                ...styles.guessLetterBox,
                backgroundColor: bgColor, // Arka plan rengi
                border: 'none', // Border'ı kaldırıyoruz
            }}
        >
            <h3>
                <b>{letter.toUpperCase()}</b>
            </h3>
        </div>
    );
};



export interface GuessWordProps {
    guess: string;
    guesses: string[];
    word: string;
    active: boolean;
    noClear?: boolean;
}

const GuessWord: React.FC<GuessWordProps> = ({
    guess,
    guesses,
    word,
    active,
    noClear,
}) => {
    const [savedGuess, setSavedGuess] = useState(guess);
    const controls = useAnimation();

    useEffect(() => {
        if (active) {
            setSavedGuess(guess);
        }

        if (
            active &&
            guess.length === word.length &&
            !WORDS.includes(guess.toLowerCase())
        ) {
            controls
                .start({
                    backgroundColor: '#f00', // Geçici kırmızı arka plan
                    x: 2,
                    transition: {
                        duration: 0.1,
                    },
                })
                .then(() => {
                    controls
                        .start({
                            x: -4,
                            backgroundColor: '#fff', // Varsayılan beyaz arka plan
                            transition: {
                                duration: 0.1,
                            },
                        })
                        .then(() => {
                            controls.start({
                                x: 0,
                                backgroundColor: '#fff',
                                transition: {
                                    duration: 0.09,
                                },
                            });
                        });
                });
        }
    }, [guess, active, word, controls]);

    useEffect(() => {
        if (guesses.length === 0 && !noClear) setSavedGuess('');
    }, [guesses, noClear]);

    return (
        <motion.div animate={controls} style={styles.guessWordRow}>
            {savedGuess.split('').map((letter, index) => (
                <GuessLetter
                    guessed={!active} // Sadece aktif değilse işlem yap
                    key={index}
                    letter={letter}
                    guess={savedGuess}
                    word={word}
                />
            ))}
            {[...Array(word.length - savedGuess.length)].map((e, i) => (
                <GuessLetter
                    guessed={!active}
                    key={i}
                    letter={' '}
                    guess={savedGuess}
                    word={word}
                />
            ))}
        </motion.div>
    );
};


export interface WordleProps {}

const TOP_ROW = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'];
const MIDDLE_ROW = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'];
const BOTTOM_ROW = ['RET', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'DEL'];
const ROWS = [TOP_ROW, MIDDLE_ROW, BOTTOM_ROW];
const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const Wordle: React.FC<WordleProps> = () => {
    const word = 'CYBER';
    const [guesses, setGuesses] = useState<string[]>([]);
    const [gameOver, setGameOver] = useState(false);
    const [won, setWon] = useState(false);
    const [currentGuess, setCurrentGuess] = useState('');

    const restart = () => {
        setGuesses([]);
        setGameOver(false);
        setTimeout(() => {
            setWon(false);
        }, 500);
        setCurrentGuess('');
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Backspace') {
                if (currentGuess.length > 0) {
                    // Eğer tahmin içinde harf varsa sil
                    setCurrentGuess(currentGuess.slice(0, -1));
                }
            } else if (event.key === 'Enter') {
                if (currentGuess.length === word.length) {
                    if (WORDS.includes(currentGuess.toLowerCase())) {
                        // Geçerli bir kelime ise tahmine ekle
                        setGuesses([...guesses, currentGuess]);
                        setCurrentGuess(''); // Yeni tahmin için sıfırla
                    } else {
                        console.log("Geçersiz kelime: ", currentGuess);
                    }
                } else {
                    console.log(
                        `Kelime uzunluğu eşleşmiyor. Girdi: ${currentGuess.length}, Beklenen: ${word.length}`
                    );
                }
            } else if (
                event.key.length === 1 &&
                ALPHABET.includes(event.key.toUpperCase())
            ) {
                if (currentGuess.length < word.length) {
                    // Geçerli bir harf ekle
                    setCurrentGuess(currentGuess + event.key.toUpperCase());
                } else {
                    console.log("Kelime zaten maksimum uzunluğa ulaştı.");
                }
            }
        };

        // Eğer hiçbir tahmin yapılmadıysa logla
        if (guesses.length === 0 && currentGuess.trim() === '') {
            console.log("Henüz hiçbir tahmin yapılmadı.");
        }

        // add listener
        window.addEventListener('keydown', handleKeyDown);

        // cleanup listener
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [guesses, currentGuess]);

    useEffect(() => {
        if (guesses.length === 6) {
            setGameOver(true);
        }
        guesses.forEach((guess) => {
            if (guess === word) {
                setGameOver(true);
                setWon(true);
            }
        });
    }, [guesses]);

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h2>Wordy</h2>
            </div>

            {/* Game Over Ekranı */}
            <motion.div
                variants={gameOverAnimations}
                animate={gameOver ? 'show' : 'hidden'}
                initial={false}
                style={Object.assign(
                    {},
                    styles.gameOverContainer,
                    gameOver && { zIndex: 1000 }
                )}
            >
                <h2>{won ? 'You win!' : 'Game Over'}</h2>

                {/* Kazanıldığında doğru kelimeyi göster */}
                {won && (
                    <>
                        <p>Thanks for playing! Key word is always "CYBER" !</p>
                        <GuessWord
                            key={'winning-guess'}
                            guess={word}
                            word={word}
                            guesses={guesses}
                            active={false}
                            noClear={true}
                        />
                        <br />
                        <div className="site-button" onMouseDown={restart}>
                            Restart Game
                        </div>
                    </>
                    
                )}

                {!won && (
                    <>
                        <p>You didn't find the correct word.</p>
                        <p><img src={think} alt='think' style={styles.think}/></p>
                        <p>Just Think Simple..</p>
                        <div className="site-button" onMouseDown={restart}>
                            Try Again
                        </div>
                    </>
                    
                )}
                
            </motion.div>

            {/* Oyun alanı */}
            <motion.div
                variants={gameAnimations}
                animate={!gameOver ? 'show' : 'hidden'}
                initial={false}
                style={styles.gameContainer}
            >
                <div style={styles.playArea}>
                    {[...Array(6)].map((e, i) => (
                        <GuessWord
                            key={i}
                            guess={currentGuess}
                            word={word}
                            guesses={guesses}
                            active={i === guesses.length}
                        />
                    ))}
                </div>

                {/* Klavye */}
                <div style={styles.keyboardContainer}>
                    {ROWS.map((row) => (
                        <div style={styles.keyboardRow} key={`row-${row[0]}`}>
                            {row.map((letter) => (
                                <KeyboardLetter
                                    key={letter}
                                    word={word}
                                    setGuesses={setGuesses}
                                    guesses={guesses}
                                    letter={letter}
                                    currentGuess={currentGuess}
                                    setCurrentGuess={setCurrentGuess}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </motion.div>
        </div>
    );
};


const gameAnimations = {
    hidden: {
        opacity: 0,
        y: -12,
        transition: {
            duration: 0.5,
        },
    },
    show: {
        y: 0,
        opacity: 1,
        transition: {
            delay: 0.5,
            duration: 0.5,
        },
    },
};

const gameOverAnimations = {
    hidden: {
        opacity: 0,
        y: 32,
        transition: {
            duration: 0.5,
        },
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.4,
            duration: 0.5,
            ease: Easing.expOut,
        },
    },
};

const styles: StyleSheetCSS = {
    think: {
        width:100
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        overflowY: 'scroll',
    },
    gameContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    gameOverContainer: {
        zIndex: -1000,
        textAlign: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    header: {
        flexShrink: 1,
        paddingTop: 32,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    keyboardContainer: {
        flexShrink: 1,

        paddingBottom: 24,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    playArea: {
        flex: 1,
        flexDirection: 'column',

        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16,
        marginBottom: 16,
    },
    letterBox: {
        padding: 12,
        paddingTop: 16,
        minWidth: 42,

        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 16,
        margin: 4,
    },
    keyboardRow: {},
    guessLetterBox: {
        width: 60,
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',

        margin: 4,
    },
    guessWordRow: {},
    emptyBox: {
        border: '2px solid gray',
        backgroundColor: 'white',
        boxShadow: 'none',
    },
};

export default Wordle;
