import React from 'react';
import currentme from '../../assets/pictures/currentme.jpeg';
import unibounty2 from '../../assets/pictures/unibounty2.jpeg';
import trakya1 from '../../assets/pictures/trakya1.jpeg';

import hobydesign from '../../assets/pictures/hobbies/webdesign.gif';
import underwater from '../../assets/pictures/hobbies/underwater.jpeg';
import sky from '../../assets/pictures/hobbies/sky.jpeg';
import offroad from '../../assets/pictures/hobbies/offroad.jpeg';
import lively from '../../assets/pictures/hobbies/lively.gif';
import onepiece from '../../assets/pictures/hobbies/onepiece3.gif';
import chess from '../../assets/pictures/hobbies/chess.gif';

import { Link } from 'react-router-dom';
import ResumeDownload from './ResumeDownload';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
    const startYear = 2018; // Year you started working
    const currentYear = new Date().getFullYear();
    const yearsOfExperience = currentYear - startYear;

    return (
        // add on resize listener
        <div className="site-page-content">
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -16 }}>Welcome</h1>
            <h3>I'm Eray Cakin</h3>
            <br />
            <div className="text-block">
                <div style={{}}>
                    <div style={{ flex: 1, textAlign: 'justify', alignSelf: 'center', flexDirection: 'column', }}>
                        <p> I graduated from Trakya University with a degree in Computer Engineering in 2013. Currently, I work as a Senior Offensive Security Expert at Turkcell, with over {yearsOfExperience} years of professional experience in Cyber Security. </p>
                        <p> Thank you for taking the time to check out my portfolio. I hope you enjoy exploring it as much as I enjoyed building it. If you have any questions or comments, feel free to contact me using{' '}
                            <Link to="/contact">this form</Link> or send an email to{' '} <a href="mailto:erycakin@gmail.com">erycakin@gmail.com</a>. </p>
                        </div>
                    <div style={styles.verticalImage1}> <img src={currentme} style={styles.profileimage} alt="" />
                        <p> <sub> <b>Figure 1:</b> Me, October 2022 </sub> </p>
                    </div>
                </div>
                <br />
                <ResumeDownload /> 
                <br />
                <h3>About Me</h3>
                <p> My career journey in Cyber Security started with a six-month government funded Cyber Security Training program at Ayvansaray University. </p>
                <p> With the success I gained in the education program, I started working as a Cyber Security Specialist. Two years later, I was invited by Ayvansaray University to give Cyber Security education to teachers. This unique experience allowed me to share my knowledge and experience with the teachers of the university where I was educated. I organized a 20-hour course focusing on the basic and practical aspects of cyber security.
                    </p>
                    <br />
                <div style={{}}>
                    <div style={{ flex: 1, textAlign: 'justify', alignSelf: 'center', flexDirection: 'column', }}>
                        <p> In addition to my technical expertise, I am passionate about sharing knowledge and supporting others in the Cyber Security community. I share digital trainings on my{' '} <a rel="noreferrer" target="_blank" href="https://www.youtube.com/@eraycakn">YouTube</a>{' '}
                        channel and {' '}<a rel="noreferrer" target="_blank" href="https://www.udemy.com/course/siber-guvenlik-temelleri-pentester-olma-yolunda/">Udemy</a>. I enjoy inspiring people interested in cybersecurity and sharing my knowledge and experience through my speeches at universities and conferences. I will always continue to improve myself and share my experiences with others.</p>
                    </div>
                    <div style={styles.verticalImage2}> 
                        <img src={unibounty2} style={styles.image2} alt="" />
                        <p> <sub> <b>Figure 2: </b> Gebze Technical University<br />Cyber Security Conference </sub> </p>
                    </div>
                </div>
                <br />
                <p> My Udemy course,{' '} <em>"Cyber Security Basics - On Your Way to Becoming a Pentester"</em>, has reached over 2,500 students and maintains a high rating. I also share practical information and mentor young people on YouTube for those interested in Cyber Security.
                        I try to contribute to the community through personal projects like {' '}<a rel="noreferrer" target="_blank" href="https://github.com/eraycakinn/BruteForcer">BruteForcer</a>{' '}that tool available on GitHub to help with penetration testing tasks. I plan to continue sharing the projects I write on Github. </p>
                        <br />
                
                <p> I started my career as a consultant performing penetration testing with a focus on internal and external networks, WiFi and Active Directory environments. Over time, I improved my expertise to include Web Applications, APIs and Mobile Application security assessments.
                These experiences increased my passion for Offensive Security and encouraged me to earn the prestigious OSCP certification. I am currently working on getting OSWE certification. Discovering and analyzing vulnerabilities not only excites me, but also increases my determination to be at the forefront of this rapidly evolving industry.  </p>
                <p>The types of security testing I have done throughout my career are as below. <br/><br/>
                <div className='row'>
                    <div className='col-md-4'>
                        <ul>
                            <li>Web Applications </li>
                            <li>Local Networks</li>
                            <li>Mobile Applications</li>
                            <li>Web API</li>
                            <li>Active Directory</li>
                            <li>Social Engineering</li>
                            <li>Cloud Security</li>
                            <li>SAST, DAST, IAST</li>
                            <li>Wi-Fi Security</li>
                            <li>Denial of Service</li>
                            
                        </ul>
                    </div>
                    <div className='col-md-8' style={{ justifyContent: 'center' }}>
                    <div style={styles.verticalImage3}> <img src={trakya1} style={styles.image} alt="" />
                        <p> <sub> <b>Figure 3:</b> Trakya University - With Students </sub> </p>
                    </div>
                    </div>
                </div>
                I actively use Vulnerability Scanning Applications such as <b>Nessus, Acunetix, Fortify, Netsparker and Burp Suite</b> to automate and improve my work. <br/><br/>If you'd like to explore more about my skill set, visit {' '}
                    <Link to="/portfolio/skills">My Skills</Link> page. </p>
                <br />
                <h3>My Hobbies</h3>
                <p>
                    Beyond Cyber Security, I have a lot of hobbies that I enjoy
                    doing in my free time. Here are some examples of my hobbies. <br/><br/>
                    I like to design modern and lively websites. <img style={styles.icons} alt="" src={lively} />
                </p>
                <div className="row">
                    <div className="col-md-12 text-center">
                        
                        <div className="captioned-image">
                            <img src={hobydesign} alt="Web design example" className="img-fluid" />
                            <p>
                                <sub>
                                    Login Screen in my project
                                </sub>
                            </p>
                        </div>
                    </div>
                </div>

                {/* İkinci Bölüm Başlık */}
                <div className="row">
                    <div className="col-md-12">
                        <p className='mt-3'>I really enjoy Extreme Sports <img style={styles.movingIcon} alt="" src={onepiece} /></p>
                        <br /><br />
                    </div>
                </div>

                {/* Üçlü Grid */}
                <div className="row">
                    {/* Birinci Grid Elemanı */}
                    <div className="col-md-4 text-center">
                        <div className="captioned-image">
                            <img src={underwater} style={styles.hobbyimages} alt="Underwater" className="img-fluid" />
                            <p>
                                <sub>
                                    The Underwater
                                </sub>
                            </p>
                        </div>
                    </div>

                    {/* İkinci Grid Elemanı */}
                    <div className="col-md-4 text-center">
                        <div className="captioned-image">
                            <img src={sky} style={styles.hobbyimages} alt="Sky Adventure" className="img-fluid" />
                            <p>
                                <sub>
                                    In The Skies
                                </sub>
                            </p>
                        </div>
                    </div>

                    {/* Üçüncü Grid Elemanı */}
                    <div className="col-md-4 text-center">
                        <div className="captioned-image">
                            <img src={offroad} style={styles.hobbyimages} alt="Offroad" className="img-fluid" />
                            <p>
                                <sub>
                                    On The Land
                                </sub>
                            </p>
                        </div>
                    </div>
                </div>

                
                <p className='mt-3 mb-3 text-center'>My favourite game is <b>CHESS !</b></p>

                <img className='mb-5' style={styles.chessImg} alt="" src={chess} />        

                <p>
                    Thanks for reading about me. I hope that you enjoy exploring
                    the rest of my portfolio website and everything it has to
                    offer.
                </p>
                <p>
                    If you have any questions or comments I would love to hear
                    them.You can reach me at{' '}
                    <a href="mailto:erycakin@gmail.com">
                    erycakin@gmail.com
                    </a>
                   
                </p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    image2: {
        height: 'auto',
        width: '100%',
    },
    profileimage: {
        height: 'auto',
        width: '100%',
    },
    hobbyimages: {
        height: '200px',
        width: '100%',
    },
    chessImg: {
        height: 'auto',
        width: '40%',
        margin:'auto'
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32,
    },
    verticalImage1: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
    },
    verticalImage2: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 20,
        flexGrow:1,
        flexShrink:1,
        flexBasis:'5%',

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent:'center'
    },
    verticalImage3: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent:'center'
    },
    icons: {
        width: 60,
        height: 60,
        marginTop:'-2%'
    },
    movingIcon: {
        height: 50,
        marginTop: '-4.5%',
        transform: 'scaleX(-1)'
      },
};



export default About;
